import React, { Component } from "react"
import { Image, Button } from "react-bootstrap"
import { OutboundLink } from 'gatsby-plugin-gtag'

class BoxSkill extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <OutboundLink
          href={this.props.link}
        >
          <Image
            alt="900x500"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
            data-src={this.props.imageSkill}
            className="image-skill lazyload"
          />
          <div className="bg-box-skill">
            <p className="text-skill">{this.props.nameSkill}</p>
            <Button className="btn-skill">
              <p to={this.props.link}>
                View More
              </p>
            </Button>
          </div>
        </OutboundLink>
      </div>
    )
  }
}

export default BoxSkill;
