import React from "react"

import Layout from "../components/layout"
import Capabilities from "../components/screens/Capabilities"
import { Helmet } from "react-helmet"

const PageCapabilities = () => (
  <Layout pageInfo={{ pageName: "Capabilities" }} sitePage="site-page">
    <Helmet defer={false}>
        <title>Capabilities</title>
        <meta name="description" content="We work with Docker, Kubernets, Gitlab, Jenskin CI/CD, Automation testing, Amazon Web Services, Google Compute Cloud, GIT, Varnish, Nginx, PHP, ReactJS, ReactNative, Go lang, NodeJS, Cucumber, Java" />
    </Helmet>
    <Capabilities />
  </Layout>
)

export default PageCapabilities
