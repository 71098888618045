import React, { Component } from "react"
import { Image } from "react-bootstrap"
import BoxSkill from "./BoxSkill"
import { bgSkill } from '../../../images'
import { BackgroundImages } from "../../../shared"


class Capabilities extends Component {
  constructor(props) {
    super(props);
    this.renderSkill = this.renderSkill.bind(this);
  }

  renderSkill() {
    return (
      <div className="skill">
        <h1 className="title-skill">Our skill & Competencies</h1>
        <BoxSkill
          imageSkill={require("../../../images/png/pts.jpg")}
          nameSkill="Php/My Sql"
          link="/capabilities/php-mysql"
          className="box-skill"
        />
        <BoxSkill
          imageSkill={require("../../../images/png/jquery-ajax.jpg")}
          nameSkill="Jquery/Ajax"
          link="/capabilities/jquery-ajax"
          className="box-skill second"
        />
        <BoxSkill
          imageSkill={require("../../../images/png/magento.jpg")}
          nameSkill="Magento"
          link="/capabilities/magento"
          className="box-skill"
        />
        <BoxSkill
          imageSkill={require("../../../images/png/joomla.jpg")}
          nameSkill="Joomla"
          link="/capabilities/joomla"
          className="box-skill second"
        />
        <BoxSkill
          imageSkill={require("../../../images/png/wordpress.jpg")}
          nameSkill="WordPress"
          link="/capabilities/wordpress"
          className="box-skill"
        />
        <BoxSkill
          imageSkill={require("../../../images/png/html-css.jpg")}
          nameSkill="Html/Css"
          link="/capabilities/html-css"
          className="box-skill second"
        />
        <BoxSkill
          imageSkill={require("../../../images/png/pts.jpg")}
          nameSkill="Pts/Illustrator"
          link="/capabilities/pts-illus"
          className="box-skill"
        />
        <BoxSkill
          imageSkill={require("../../../images/png/nginx.jpg")}
          nameSkill="Nginx"
          link="/capabilities/nginx"
          className="box-skill second"
        />
        <BoxSkill
          imageSkill={require("../../../images/png/varnish.jpg")}
          nameSkill="Varnish"
          link="/capabilities/varnish"
          className="box-skill"
        />
      </div>
    )
  }

  render() {
    return (
      <div className="wrapper-capabilities">
        <div className="top-capabilities">
          <div className="mobi-bg-capabilities">
            <BackgroundImages style={{
              backgroundImage: `url(${bgSkill})`,
              zIndex: -1
            }}>
              <div className="box-bg-capabilities" />
            </BackgroundImages>
          </div>
          <Image
            alt="900x500"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=" 
            data-src={require("../../../images/png/4.jpg")}
            className="hidden-desktop lazyload"
          />
          <div className="box-top-capabilities">
            <p className="text">
              Open Techiz is a Vietnam-based Web/ Mobile development company for the large retailers and big brands.
            </p>
            <p className="text">
              We've worked with enterprise-level companiesto deliver high value e-commerce services and solutions with strategic consulting, functional design and top-tier engineering capabilities.
            </p>
            <p className="text" style={{ marginBottom: `0` }}>
              We cover spectrum of Web Development Service. Completely e-commerce & CMS web site production with full control on quality standards.We also have extensive experience working with big catalog size with 100K+ items.
            </p>
          </div>
        </div>
        {this.renderSkill()}
      </div>
    )
  }
}

export default Capabilities;
